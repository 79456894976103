<div class="main-content">
  <app-side-menu></app-side-menu>
  <div class="right-content">
    <app-top-menu></app-top-menu>
    <div class="content">
      <span class="title">Nova Campanha</span>
      <form [formGroup]="myForm" (ngSubmit)="uploadFiles()">
        <div class="green-div">
          <div class="output-template" (click)="onCampanhasDownload()">
            <span>Download do template</span>
            <img src="../../../assets/Icons/down.png" alt="download" />
          </div>
          <div class="row-double">
            <div>
              <!-- Upload novo produto -->
              <div class="upload-template">
                <div class="d-flex flex-row">
                  <span class="text-description">Upload nova Campanha</span>
                  <span class="required">*</span>
                </div>
                <mat-form-field>
                  <ngx-mat-file-input [formControl]="imgProduct" [accept]="excelType" accept=".xls, .xlsx"
                    (change)="onFileSelected($event)">
                  </ngx-mat-file-input>
                </mat-form-field>
              </div>
            </div>
            <div>
              <!-- Upload de Imagem (550x550px) -->
              <div class="upload-img">
                <span class="text-description">Upload de Imagem (550x550px)</span>
                <mat-form-field>
                  <ngx-mat-file-input [formControl]="imgFile" [accept]="imgType">
                  </ngx-mat-file-input>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <button class="btn-next d-flex" type="submit">
          <span>Criar campanha</span>
          <img src="../../../assets/Icons/icon-done.png" alt="done" />
        </button>
      </form>
    </div>
    <app-footer></app-footer>
  </div>
</div>