<div class="comunicado-page">
    <div class="curve-container">
        <img src="../../../assets/images/wave.svg" alt="Waves" />
    </div>
    <div>
        <img src="../../../assets/images/bio2Logo.png" class="logo-bio2" alt="Logo BIO2" />
    </div>

    <div class="comunicado-container">
        <span class="title-comunicado">Comunicado</span>
        <div class="comunicado-text">
            <span *ngIf="activeComunicado" [innerHTML]="activeComunicado.description"></span>
        </div>
        <ng-container *ngIf="!this.isLoading">
            <img [src]="getImage()" alt="Imagem do comunicado" class="comunicadoImg"/>
        </ng-container>
        <div class="d-flex flex-row btns">
            <div class="btn-continue d-flex flex-row-reverse" (click)="onContinue()">
                <mat-icon svgIcon="arrow_right" iconPositionEnd></mat-icon>
                Compreendo
            </div>
        </div>
    </div>

    <div class="bird-container">
        <img src="../../../assets/images/ellipse2.svg" class="ellipse" alt="Ellipse" />
        <img src="../../../assets/images/bird.png" class="bird" alt="Bird" />
    </div>
</div>