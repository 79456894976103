import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';
import { ApiConfiguration } from '../api-configuration';
import { Observable, catchError } from 'rxjs';
import {
  sendEmailCampanha,
  sendEmailProduts,
  uploadProduct,
} from '../models/uploadProduct';

@Injectable({
  providedIn: 'root',
})
export class CreateProductService {
  route = new ApiConfiguration();
  ApiSendNewData = this.route.rootUrl + '/api/shop/campaign/send-new-data';

  constructor(private http: HttpClient) {}

  sendNewData(files: FormData): Observable<any> {
    return this.http.post(this.ApiSendNewData, files);
  }
}
