import { Bio2Tokens } from './../models/bio2token';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiConfiguration } from '../api-configuration';
import {
  ProfileDataModel,
  ProfileDataRequest,
  ProfileUpdateAddress,
  ProfileUpdatePassword,
} from '../models/perfil';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, of } from 'rxjs';
import { Router } from '@angular/router';
import { EncryptionService } from './encryption.service';
import { StorageKeys } from '../models/storageKeys';

@Injectable({
  providedIn: 'root',
})
export class PerfilService {
  route = new ApiConfiguration();
  ApiGetProfileDataPath = this.route.rootUrl + '/api/perfil/profile-data';
  ApiUpdateAddressPath =
    this.route.rootUrl + '/api/perfil/update-user-pref-address';
  ApiUpdatePasswordPath =
    this.route.rootUrl + '/api/perfil/update-user-password';
  ApiUpdateAvatarPath = this.route.rootUrl + '/api/perfil/update-avatar';

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private router: Router,
    private encrypt: EncryptionService
  ) { }

  getCurrentUserInfo(): ProfileDataRequest {
    let bio2Token = localStorage.getItem('BIO2_AccessToken');
    if (!bio2Token) {
      this.router.navigate(['/home']);
    }
    const tokenData = this.authService.getDecodedAccessToken(bio2Token!);
    const role = tokenData['role'];
    const username = tokenData['unique_name'];
    const sessionClientNumber = this.encrypt.getDecryptedItem(StorageKeys.BIO2_CLIENT_ID)!;
    const sessionClientUsername = this.encrypt.getDecryptedItem(StorageKeys.BIO2_CLIENT_USERNAME)!;
    const sessionClientCommercialCondition = this.encrypt.getDecryptedItem(StorageKeys.BIO2_CLIENT_COMMERCIAL_CONDITION) ?? "1";
    if (role === 'Administrador' || role === 'Laboratorio') {
      return {
        name: tokenData['name'],
        clientId: sessionClientNumber,
        usernameClient: sessionClientUsername,
        userId: tokenData['registoId'],
        role: role,
        username: username,
        avatar: tokenData['avatar'],
        commercialCondition: parseInt(sessionClientCommercialCondition)
      };
    }
    return {
      name: tokenData['name'],
      clientId: tokenData['clientId'],
      userId: tokenData['registoId'],
      role: role,
      username: username,
      avatar: tokenData['avatar'],
      commercialCondition: tokenData['commercialCondition']
    };
  }

  getUserProfileData(clientId: string, userId: string): Observable<ProfileDataModel> {
    let Bio2User = localStorage.getItem('BIO2_User')
    if (Bio2User && Bio2User != '') {
      return this.profileDataParse(Bio2User)
    }
    return this.http.get<ProfileDataModel>(`${this.ApiGetProfileDataPath}/${clientId}/${userId}`)
      .pipe(
        catchError((error) => {
          throw error;
        })
      );
  }

  updatePrefAddress(request: ProfileUpdateAddress): Observable<boolean> {
    return this.http.put<boolean>(`${this.ApiUpdateAddressPath}/${request.userId}/${request.deliveryAddressId}`, null)
      .pipe(
        catchError((error) => {
          throw error;
        })
      );
  }

  updatePassword(request: ProfileUpdatePassword): Observable<boolean> {
    return this.http.put<boolean>(`${this.ApiUpdatePasswordPath}/${request.userId}/${request.password}`, null)
      .pipe(
        catchError((error) => {
          throw error;
        })
      );
  }

  updateAvatarIcon(userId: string, avatar: string): Observable<boolean> {
    try {
      let params = new HttpParams();
      params = params.append('userId', String(userId));
      params = params.append('avatar', avatar);
      return this.http.get<boolean>(this.ApiUpdateAvatarPath, { params }).pipe(
        catchError((error) => {
          throw error;
        })
      );
    } catch (error) {
      throw error;
    }
  }

  getProfileData() {
    let loggedUser: ProfileDataRequest = this.getCurrentUserInfo();
    this.getUserProfileData(loggedUser.clientId, loggedUser.userId)
      .subscribe(
        (profileData) => {
          let profileStr = this.profileDataToString(profileData)
          localStorage.setItem('BIO2_User', profileStr!)
        },
        (error) => {
          console.error('Error fetching profile data:', error);
        }
      );
  }

  profileDataToString(profile: ProfileDataModel) {
    let profileToStr = JSON.stringify(profile)
    let profileStrEncript = this.encrypt.encrypt(profileToStr)
    return profileStrEncript;
  }

  profileDataParse(profile: string): Observable<ProfileDataModel> {
    let profileParseDsc = this.encrypt.decrypt(profile);
    let profileParse = JSON.parse(profileParseDsc!);
    return of(profileParse);
  }
}
