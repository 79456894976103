import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { CreateProductService } from './../../api/services/create-product.service';
import { Component, OnInit } from '@angular/core';
import { sendEmailProduts } from 'src/app/api/models/uploadProduct';
import { SnackBarService } from 'src/app/api/services/snack-bar.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { PerfilService } from 'src/app/api/services/perfil.service';

@Component({
  selector: 'app-add-produtos',
  templateUrl: './add-produtos.component.html',
  styleUrls: ['./add-produtos.component.css'],
})
export class AddProdutosComponent implements OnInit {
  submitted: boolean = false;
  myForm!: FormGroup;
  imgType = '.png,.jpeg,.jpg,.gif,.webm';
  fileType = '.pdf';
  excelType = '.xls, .xlsx';

  constructor(
    private perfilService: PerfilService,
    private createProductService: CreateProductService,
    private snackBarService: SnackBarService,
    private fb: FormBuilder,
    private router: Router
  ) {}
  ngOnInit(): void {
    this.myForm = this.fb.group({
      imgProduct: ['', Validators.required],
      technicalFile: [''],
      imgFile: ['', Validators.required],
      flyerFile: [''],
    });
  }

  get imgProduct() {
    return this.myForm.controls['imgProduct'] as FormControl;
  }
  get technicalFile() {
    return this.myForm.controls['technicalFile'] as FormControl;
  }
  get imgFile() {
    return this.myForm.controls['imgFile'] as FormControl;
  }
  get flyerFile() {
    return this.myForm.controls['flyerFile'] as FormControl;
  }

  uploadProduct() {
    this.submitted = true;

    const bula = this.flyerFile?.value;
    const imgFile = this.imgFile?.value;
    const newProductFile = this.imgProduct?.value;
    const technicalFile = this.technicalFile?.value;

    const formData: FormData = new FormData();
    if (bula) {
      formData.append('Files', bula, 'Bula.' + bula.type.replace(/(.*)\//g, ''));
    }
    if (technicalFile) {
      formData.append('Files', technicalFile, 'TechnicalFile.' + technicalFile.type.replace(/(.*)\//g, ''));
    }
    formData.append('Files', imgFile, 'Imagem.' + imgFile.type.replace(/(.*)\//g, ''));
    formData.append('Files', newProductFile, 'NewProductFile.' + newProductFile.name.split('.').pop());


    if (this.myForm.valid) {
      this.createProductService.sendNewData(formData).subscribe({
        next: (resp) => {
          if (resp) {
            this.snackBarService.openSnackBar(
              'Ação concluida com sucesso',
              '#0AA174',
              '../../../assets/Icons/correct.png'
            );
            this.myForm.reset();
          }
        },
        error: (e) => {
          this.snackBarService.openSnackBar(
            `Ocorreu um erro`,
            '#F97066',
            '../../../assets/Icons/ErrorIcon.png'
          );
          console.error(e);
        },
      });
    } else {
      this.snackBarService.openSnackBar(
        `Campos não preenchidos`,
        '#F97066',
        '../../../assets/Icons/ErrorIcon.png'
      );
    }
  }

  onProductDownload() {
    let fileUrl: string = `${environment.uploadTemplates}TemplateProdutos.xlsx`;
    window.open(fileUrl, '_blank');
    this.snackBarService.openSnackBar(
      'Download concluido com sucesso',
      '#0AA174',
      '../../../assets/Icons/correct.png'
    );
  }

  onFileSelected(event: any): void {
    const selectedFile = this.imgProduct.value.name;
    if (selectedFile) {
      if (this.isExcelFile(selectedFile)) {
      } else {
        this.myForm.get('imgProduct')!.reset();
        this.snackBarService.openSnackBar(
          `Tipo de ficheiro inválido`,
          '#F97066',
          '../../../assets/Icons/ErrorIcon.png'
        );
      }
    }
  }

  isExcelFile(fileName: string): boolean {
    return fileName.endsWith('.xls') || fileName.endsWith('.xlsx');
  }
}
