<div class="d-flex flex-row" style="background-color:#F5F8F3">
  <app-dashboard-side-menu></app-dashboard-side-menu>
  <div class="content d-flex flex-column w-100">
    <app-dashboard-top-menu></app-dashboard-top-menu>
    <div *ngIf="loading" class="loading-animation">
      <app-loading></app-loading>
    </div>
    <div *ngIf="!loading">
      <div *ngIf="!isEddingNewGroups">
        <h1>
          <nav class="d-flex flex-row gap-10">
            <span>
              <img class="icon" src="../../../../assets/Icons/ArrowLeft.png" (click)="navigateBack()">
            </span>
            <span class="breadcrumb-title">
              {{campaign.name}}
            </span>
          </nav>
        </h1>
      </div>
      <div *ngIf="!isAdding">
        <form [formGroup]="campaingForm">
          <div class="d-flex flex-row w-100" style="gap:98px; padding-bottom:24px">
            <div class="d-flex flex-column w-50" style="gap:32px">
              <div class="d-flex flex-column">
                <span class="text-description">Tipo de Campanha</span>
                <mat-form-field>
                  <mat-select formControlName="campaignType">
                    <mat-option value="C">Campanhas</mat-option>
                    <mat-option value="E">Exclusivas</mat-option>
                    <mat-option value="F">Flash</mat-option>
                    <mat-option value="N">Novidades</mat-option>
                    <mat-option value="P">Parcerias</mat-option>
                    <mat-option value="P1">Publicidade 1</mat-option>
                    <mat-option value="P2">Publicidade 2</mat-option>
                    <mat-option value="P3">Publicidade 3</mat-option>
                    <mat-option value="P4">Publicidade 4</mat-option>
                    <mat-option value="DH">Display na Loja</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="d-flex flex-column">
                <span class="text-description">Data de Início</span>
                <mat-form-field>
                  <input matInput [matDatepicker]="picker1" formControlName="startDate">
                  <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                  <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="d-flex flex-column">
                <span class="text-description">Nível de Preço</span>
                <mat-form-field>
                  <mat-select formControlName="priceLevel" multiple>
                    <mat-option value="0">Nivel 0</mat-option>
                    <mat-option value="1">Nivel 1</mat-option>
                    <mat-option value="2">Nivel 2</mat-option>
                    <mat-option value="3">Nivel 3</mat-option>
                    <mat-option value="4">Nivel 4</mat-option>
                    <mat-option value="5">Nivel 5</mat-option>
                    <mat-option value="6">Nivel 6</mat-option>
                    <mat-option value="7">Nivel 7</mat-option>
                    <mat-option value="8">Nivel 8</mat-option>
                    <mat-option value="9">Nivel 9</mat-option>
                    <mat-option value="10">Nivel 10</mat-option>
                    <mat-option value="11">Nivel 11</mat-option>
                    <mat-option value="12">Nivel 12</mat-option>
                    <mat-option value="12">Nivel 13</mat-option>
                    <mat-option value="12">Nivel 14</mat-option>
                    <mat-option value="12">Nivel 15</mat-option>
                    <mat-option value="12">Nivel 16</mat-option>
                    <mat-option value="12">Nivel 17</mat-option>
                    <mat-option value="12">Nivel 18</mat-option>
                    <mat-option value="12">Nivel 19</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="d-flex flex-column">
                <span class="text-description">Imagem p/ Header (1400 x 400px)</span>
                <mat-form-field>
                  <ngx-mat-file-input [formControl]="cpgHeader" [accept]="imgGroupType">
                  </ngx-mat-file-input>
                </mat-form-field>
              </div>
              <div class="d-flex flex-column">
                <span class="text-description">PDF</span>
                <mat-form-field>
                  <ngx-mat-file-input [formControl]="cpgPdf" [accept]="pdfType">
                  </ngx-mat-file-input>
                </mat-form-field>
              </div>
            </div>
            <div class="d-flex flex-column w-50" style="gap:32px">
              <div class="d-flex flex-column">
                <span class="text-description">Nome</span>
                <mat-form-field>
                  <input matInput formControlName="campaignName">
                </mat-form-field>
              </div>

              <div class="d-flex flex-column">
                <span class="text-description">Data de Fim</span>
                <mat-form-field>
                  <input matInput [matDatepicker]="picker2" formControlName="endDate">
                  <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                  <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>
              </div>

              <div class="d-flex flex-column">
                <span class="text-description">Link</span>
                <mat-form-field>
                  <input matInput formControlName="campaignLink">
                </mat-form-field>
              </div>

              <div class="d-flex flex-column">
                <span class="text-description">Imagem Campanha (dinamico)</span>
                <mat-form-field>
                  <ngx-mat-file-input [formControl]="cpgImage" [accept]="imgGroupType">
                  </ngx-mat-file-input>
                </mat-form-field>
              </div>


              <div class="d-flex flex-column">
                <span class="text-description">Associar Laboratório
                </span>
                <mat-form-field>
                  <input type="text" aria-label="Pesquisa por laboratório" matInput [formControl]="laboratoryControl"
                    [matAutocomplete]="labAuto" [value]="this.receivedLab.labName"/>
                    <mat-autocomplete #labAuto="matAutocomplete" (optionSelected)="selectedLaboratory($event)"
                    [displayWith]="displayLaboratory">
                    <mat-option *ngFor="let option of filteredLaboratoryOptions | async" [value]="option">
                      {{ option.labName }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>

              <div class="d-flex flex-column" *ngIf="!edit">
                <div class="submit-button mb-3" style="padding-top:24px">
                  <button class="submit-inner-button" type="button" (click)="clearNewGroups(true)">
                    Adicionar grupo
                    <img  src="../../../../assets/Icons/plus-icon.png" alt="IconPlus">
                  </button>
                </div>
              </div>

            </div>
          </div>

          <div *ngIf="loadingEdit" style="padding-top: 25px;">
            <h2 class="title">Edição {{group[0].description}}</h2>
            <form [formGroup]="groupForm" class="d-flex flex-column">
              <div class="w-100 d-flex flex-column" style="gap:32px">
                <div class="d-flex flex-column">
                  <span class="text-description">Nome de Grupo <span class="required">*</span></span>
                  <mat-form-field>
                    <input matInput type="text" formControlName="groupName" (input)="updateGroup()">
                  </mat-form-field>
                </div>
                <div class="d-flex flex-column">
                  <span class="text-description">Produtos <span class="required">*</span></span>
                  <app-search-input-products
                    [isMultiple]="true"
                    [plist]="plist" 
                    [searchControl]="myControl" 
                    [selectProducts]="selectProducts" 
                    [selectedItems]="selectedItens"
                    (selectionChange)="updateGroup()">
                  </app-search-input-products>
                </div>
                <div class="d-flex flex-row w-100" style="gap:98px">
                  <div class="d-flex flex-column w-50">
                    <label>Imagem p/ Header</label>
                    <mat-form-field>
                      <ngx-mat-file-input [formControl]="groupHeader" [accept]="imgGroupType" (input)="updateGroup()">
                      </ngx-mat-file-input>
                    </mat-form-field>
                  </div>
                  <div class="d-flex flex-column w-50">
                    <label>Imagem p/ Grupo</label>
                    <mat-form-field>
                      <ngx-mat-file-input [formControl]="groupImg" [accept]="imgGroupType" (input)="updateGroup()">
                      </ngx-mat-file-input>
                    </mat-form-field>
                  </div>
                </div>
                <div>
                  <app-display-campaign-group [selectedItems]="selectedItens"></app-display-campaign-group>
                </div>
                <div class="d-flex button-edit mb-3">
                  <button class="back-submit-inner-button" type="button"
                    (click)="handleCancelButton()">
                    Cancelar
                    <img src="../../../../assets/Icons/close-line.png" alt="IconClose" style="margin-bottom: 2px;">
                  </button>
                  <button class="submit-inner-button" type="button" mat-button color="primary" style="color: white;"
                    (click)="handleEditState()">
                    Guardar Alteração
                    <img src="../../../../assets/Icons/icon-done.png" alt="IconDone" style="margin-bottom: 2px;">
                  </button>
                </div>
              </div>
            </form>
          </div>

          <div *ngIf="!loadingEdit && !isAdding && !isEddingNewGroups">
            <app-display-campaign-group></app-display-campaign-group>
          </div>
          <div class="submit-button mb-3" style="padding-top:24px" *ngIf="!isAdding && !edit">
            <button class="submit-inner-button" type="submit" (click)="submitCampaign()">Guardar Campanha
              <img src="../../../../assets/Icons/IconSeguinte.png" alt="IconSeguinte">
            </button>
          </div>
        </form>
      </div>

      <div *ngIf="isAdding && !isEddingNewGroups">
        <app-create-campaign-group></app-create-campaign-group>
        <app-display-campaign-group [isAddingNewGroups]="true" *ngIf="campaign.group != undefined && campaign.group.length > 0">
        </app-display-campaign-group>

        <div class="button-next-back mb-4">
          <button class="back-submit-inner-button"  (click)="clearNewGroups(false)">
            <img src="../../../../assets/Icons/icon-back.png" alt="IocnBack">Anterior
          </button>
          <button class="submit-inner-button" [disabled]="getNewGroups().length <= 0" (click)="addNewGroup()">Guardar
            <img src="../../../../assets/Icons/IconSeguinte.png" alt="IconSeguinte">
          </button>
        </div>
      </div>

      <div *ngIf="isAdding && isEddingNewGroups">
        <app-edit-campaign-group (emitOriginalGroup)="newEditingGroupOriginal($event)" (outSelectedItems)="getSelectedItems($event)">
        </app-edit-campaign-group>

        <app-display-campaign-group [selectedItems]="selectedItems" [isAddingNewGroups]="true" *ngIf="campaign.group != undefined && campaign.group.length > 0">
        </app-display-campaign-group>

        <div class="d-flex button-edit mb-3">
          <button class="back-submit-inner-button" type="button"
            (click)="handleCancelButton()">
            Cancelar
            <img src="../../../../assets/Icons/close-line.png" alt="IconClose" style="margin-bottom: 2px;">
          </button>
          <button class="submit-inner-button" type="button" mat-button color="primary" style="color: white;"
            (click)="handleEditState()">
            Guardar Alteração
            <img src="../../../../assets/Icons/icon-done.png" alt="IconDone" style="margin-bottom: 2px;">
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
