<div class="d-flex flex-row" style="background-color:#F5F8F3">
  <app-dashboard-side-menu></app-dashboard-side-menu>
  <div class="content d-flex flex-column w-100">
    <app-dashboard-top-menu></app-dashboard-top-menu>
    <div *ngIf="isLoading" class="loading-animation">
      <app-loading></app-loading>
    </div>
    <div *ngIf="!isLoading">
      <mat-horizontal-stepper [linear]="isLinear" #stepper="matHorizontalStepper">
        <mat-step>
          <ng-template matStepLabel>1</ng-template>
          <h2 class="title">Criar Campanha</h2>
          <form [formGroup]="campaingForm">
            <div class="d-flex flex-row w-100" style="gap:98px">
              <div class="d-flex flex-column w-50" style="gap:32px">
                <div class="d-flex flex-column">
                  <span class="text-description">Tipo de Campanha <span class="required">*</span></span>
                  <mat-form-field>
                    <mat-select formControlName="campaignType" required>
                      <mat-option value="C">Campanhas</mat-option>
                      <mat-option value="E">Exclusivas</mat-option>
                      <mat-option value="F">Flash</mat-option>
                      <mat-option value="N">Novidades</mat-option>
                      <mat-option value="P">Parcerias</mat-option>
                      <mat-option value="P1">Publicidade 1</mat-option>
                      <mat-option value="P2">Publicidade 2</mat-option>
                      <mat-option value="P3">Publicidade 3</mat-option>
                      <mat-option value="P4">Publicidade 4</mat-option>
                      <mat-option value="DH">Display na Loja</mat-option>
                      <mat-option value="B">Banner Home</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="d-flex flex-column">
                  <span class="text-description">Data de Início <span class="required">*</span></span>
                  <mat-form-field>
                    <input matInput [matDatepicker]="picker1" formControlName="startDate" required>
                    <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                  </mat-form-field>
                </div>
                <div class="d-flex flex-column">
                  <span class="text-description">Nível de Preço <span class="required">*</span></span>
                  <mat-form-field>
                    <mat-select formControlName="priceLevel" multiple required>
                      <mat-option value="0">Nivel 0</mat-option>
                      <mat-option value="1">Nivel 1</mat-option>
                      <mat-option value="2">Nivel 2</mat-option>
                      <mat-option value="3">Nivel 3</mat-option>
                      <mat-option value="4">Nivel 4</mat-option>
                      <mat-option value="5">Nivel 5</mat-option>
                      <mat-option value="6">Nivel 6</mat-option>
                      <mat-option value="7">Nivel 7</mat-option>
                      <mat-option value="8">Nivel 8</mat-option>
                      <mat-option value="9">Nivel 9</mat-option>
                      <mat-option value="10">Nivel 10</mat-option>
                      <mat-option value="11">Nivel 11</mat-option>
                      <mat-option value="12">Nivel 12</mat-option>
                      <mat-option value="12">Nivel 13</mat-option>
                      <mat-option value="12">Nivel 14</mat-option>
                      <mat-option value="12">Nivel 15</mat-option>
                      <mat-option value="12">Nivel 16</mat-option>
                      <mat-option value="12">Nivel 17</mat-option>
                      <mat-option value="12">Nivel 18</mat-option>
                      <mat-option value="12">Nivel 19</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="d-flex flex-column">
                  <span class="text-description">Banner da Campanha (1700 x 400px)</span>
                  <mat-form-field>
                    <ngx-mat-file-input [formControl]="Header" [accept]="imgType" #FileInputHeader
                      (change)="checkFileSize(FileInputHeader, 5)">
                    </ngx-mat-file-input>
                  </mat-form-field>
                </div>
                <div class="d-flex flex-column">
                  <span class="text-description">PDF</span>
                  <mat-form-field>
                    <ngx-mat-file-input [formControl]="pdf" [accept]="fileType" #FileInputPdf
                      (change)="checkFileSize(FileInputPdf, 5)">
                    </ngx-mat-file-input>
                  </mat-form-field>
                </div>
              </div>
              <div class="d-flex flex-column w-50" style="gap:32px">
                <div class="d-flex flex-column">
                  <span class="text-description">Nome <span class="required">*</span></span>
                  <mat-form-field>
                    <input matInput formControlName="campaignName" required>
                  </mat-form-field>
                </div>

                <div class="d-flex flex-column">
                  <span class="text-description">Data de Fim <span class="required">*</span></span>
                  <mat-form-field>
                    <input matInput [matDatepicker]="picker2" formControlName="endDate" required>
                    <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                  </mat-form-field>
                </div>

                <div class="d-flex flex-column">
                  <span class="text-description">Link</span>
                  <mat-form-field>
                    <input matInput formControlName="campaignLink">
                  </mat-form-field>
                </div>

                <div class="d-flex flex-column">
                  <span class="text-description">Imagem da campanha
                    <span>{{ campaignType[campaingForm.get('campaignType')!.value] }}</span>
                  </span>
                  <mat-form-field>
                    <ngx-mat-file-input [formControl]="ImageCampanha" [accept]="imgType" #FileInputImg
                      (change)="checkFileSize(FileInputImg, 5)">
                    </ngx-mat-file-input>
                  </mat-form-field>
                </div>
                <div class="d-flex flex-column">
                  <span class="text-description">Associar Laboratório
                  </span>
                  <mat-form-field>
                    <input type="text" aria-label="Pesquisa por laboratório" matInput [formControl]="laboratoryControl"
                      [matAutocomplete]="labAuto" />
                    <mat-autocomplete #labAuto="matAutocomplete" (optionSelected)="selectedLaboratory($event)"
                      [displayWith]="displayLaboratory">
                      <mat-option *ngFor="let option of filteredLaboratoryOptions | async" [value]="option">
                        {{ option.labName }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="submit-button" style="padding-top:24px">
              <button class="submit-inner-button" matStepperNext type="submit" (click)="createCampaign()"
                [disabled]="!campaingForm.valid">Seguinte
                <img src="../../../../assets/Icons/IconSeguinte.png" alt="IconSeguinte">
              </button>
            </div>
          </form>
        </mat-step>
        <mat-step>
          <div *ngIf="!editing">
            <app-create-campaign-group>
            </app-create-campaign-group>
            <app-display-campaign-group *ngIf="campaign.group != undefined && campaign.group.length > 0">
            </app-display-campaign-group>
            <div class="button-next-back">
              <button class="back-submit-inner-button" matStepperPrevious>
                <img src="../../../../assets/Icons/icon-back.png" alt="">
                Anterior</button>
              <button class="submit-inner-button" matStepperNext
                [disabled]="!(campaign.group != undefined && campaign.group.length > 0)">Seguinte
                <img src="../../../../assets/Icons/IconSeguinte.png" alt="IconSeguinte">
              </button>
            </div>
          </div>
          <div *ngIf="editing">
            <app-edit-campaign-group (emitOriginalGroup)="getOriginalGroup($event)" (outSelectedItems)="getSelectedItems($event)">
            </app-edit-campaign-group>
            <app-display-campaign-group [selectedItems]="selectedItems" *ngIf="campaign.group != undefined && campaign.group.length > 0">
            </app-display-campaign-group>
            <div class="btns-edit-cancel">
              <button type="button" class="back-submit-inner-button" (click)="cancelOperationButton()">Cancelar</button>
              <button type="button" class="saveCampaigns" (click)="saveGroupChanges()">Guardar alterações</button>
            </div>
          </div>
        </mat-step>
        <mat-step>
          <div class="d-flex flex-row w-100 formGaps">
            <div class="d-flex flex-column w-50 justify-content-between">
              <div class="d-flex flex-column">
                <span class="span-label">
                  Tipo de Campanha
                </span>
                <span class="span-content">
                  {{campaign.type | campaignsTypeConvert}}
                </span>
              </div>
              <div class="d-flex flex-column">
                <span class="span-label">
                  Data Inicio
                </span>
                <span class="span-content">
                  {{campaign.startingDate | date:'dd/MM/yyyy'}}
                </span>
              </div>
              <div class="d-flex flex-column">
                <span class="span-label">
                  Nível de Preço
                </span>
                <span class="span-content">
                  <ng-container *ngFor="let priceLevel of campaign.priceLevel; let last = last">
                    {{ 'Nível ' + priceLevel + (last ? '' : ', ') }}
                  </ng-container>
                </span>
              </div>
            </div>
            <div class="d-flex flex-column w-50" style="gap:32px">
              <div class="d-flex flex-column">
                <span class="span-label">
                  Nome da Campanha
                </span>
                <span class="span-content">
                  {{campaign.name}}
                </span>
              </div>
              <div class="d-flex flex-column">
                <span class="span-label">
                  Data Fim
                </span>
                <span class="span-content">
                  {{campaign.endingDate | date:'dd/MM/yyyy'}}
                </span>
              </div>
              <div class="d-flex flex-column">
                <span class="span-label">
                  Link
                </span>
                <span class="span-content">
                  {{campaign.link}}
                </span>
              </div>

            </div>
          </div>
          <ng-template matStepLabel>3</ng-template>
          <app-display-campaign-group *ngIf="campaign.group != undefined && campaign.group.length > 0">
          </app-display-campaign-group>
          <div class="d-flex flex-row finish-campaign">
            <button matStepperPrevious class="back-submit-inner-button">Anterior</button>
            <button class="submit-inner-button d-flex" style="flex-basis: fit-content;"
              (click)="createFullCampaign()">Criar Campanha</button>
          </div>
        </mat-step>
      </mat-horizontal-stepper>
    </div>
  </div>
</div>
