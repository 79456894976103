import { PerfilService } from 'src/app/api/services/perfil.service';
import { SubLoginService } from './../api/services/sub-login.service';
import { Component, OnInit } from '@angular/core';
import { Address, UsersRequest, User, UsersResponse } from '../api/models/getUsers';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, Observable, Subscription, debounceTime, distinctUntilChanged, filter, tap } from 'rxjs';
import { Router } from '@angular/router';
import { EncryptionService } from '../api/services/encryption.service';
import { StorageKeys } from '../api/models/storageKeys';
import { ProfileDataRequest } from '../api/models/perfil';

@Component({
  selector: 'app-sub-login',
  templateUrl: './sub-login.component.html',
  styleUrls: ['./sub-login.component.css'],
})
export class SubLoginComponent implements OnInit {
  private usersSubject: BehaviorSubject<User[]> = new BehaviorSubject<User[]>([]);
  public userObservable: Observable<User[]> = this.usersSubject.asObservable();
  users: User[] = [];
  newUsers: User[] = [];
  loading = true;
  clientSelected: User | undefined;
  addressSelected: Address | undefined;
  alreadyRequested: boolean = false;

  myControl = new FormControl<string | User>('');

  request: UsersRequest = {
    page: 1,
    pageSize: 10,
    sortColumn: '',
    sortOrder: '',
    filterName: '',
    filterValue: '',
  };

  searchTerm: string = '';
  oldSearchTerm: string = '';
  nextPage: boolean = false;
  apiResponse: number = 0;

  private timeoutId: any;
  private delay: number = 300;
  private sugestionsSubs!: Subscription;

  constructor(
    private SubLoginService: SubLoginService,
    private router: Router,
    private encrypt: EncryptionService,
    private PerfilService: PerfilService
  ) { }

  ngOnInit(): void {
    this.removeStorage();
    this.sugestionsSubs = this.myControl.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      filter(value => value != null && typeof value === 'string'),
      tap(value => {

        const inputValue = value as string;

        if (this.oldSearchTerm !== value) {
          this.request.page = 1;
          this.oldSearchTerm = inputValue;
          this.users = [];
          this.usersSubject.next(this.users);
        }

        if (inputValue.length > 2) {
          this.searchTerm = inputValue;
          this.fetchUsers();
        }
        else if (inputValue.length === 0) {
          this.searchTerm = '';
          this.request.page = 1;
          this.fetchUsers();
        }
      })
    ).subscribe();

  }


  removeStorage() {
    localStorage.removeItem('BIO2_User')
    sessionStorage.removeItem('firstTimeDialogShop');
    sessionStorage.removeItem('observations');
    localStorage.removeItem('BIO2_ClienteAddresses')
    localStorage.removeItem('BIO2_SelectedChangedAddress')
  }

  fetchUsers(): void {
    this.loading = true;

    const usersSubs = this.SubLoginService.getUsers(this.request, this.searchTerm).subscribe({
      next: (response: UsersResponse) => {
        if (this.request.page == 1) {
          this.usersSubject.next(response.data);
          this.users = response.data;
        } else if (this.request.page > 1) {
          this.newUsers = response.data;
          this.users = this.users.concat(this.newUsers);
          this.usersSubject.next(this.users);
        }

        this.nextPage = response.hasNextPage;
        this.apiResponse = response.totalCount;
        this.loading = false;
        this.alreadyRequested = false;
      },
      complete: () => {
        if (usersSubs) usersSubs.unsubscribe();
        this.loading = false;
      },
      error: error => {
        console.log(error);
        if (usersSubs) usersSubs.unsubscribe();
      }
    });
  }

  onScroll() {
    if (this.nextPage && this.alreadyRequested == false) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId); // clear the previous timer
      }

      this.timeoutId = setTimeout(() => {
        this.alreadyRequested = true;
        this.request.page++;
        this.fetchUsers();
      }, this.delay);
    }
  }

  saveAddress(): void {
    this.encrypt.setEncryptedItem(
      StorageKeys.BIO2_CLIENT_ADDRESS_ID,
      this.addressSelected!.id.toString()
    );
    this.encrypt.setEncryptedItem(
      StorageKeys.BIO2_CLIENT_ID,
      this.clientSelected!.clientNumber.toString()
    );
    this.encrypt.setEncryptedItem(
      StorageKeys.BIO2_CLIENT_USERNAME,
      this.clientSelected!.username.toString()
    );
    this.encrypt.setEncryptedItem(
      StorageKeys.BIO2_CLIENT_COMMERCIAL_CONDITION,
      this.clientSelected!.commercialCondition.toString()
    );
    this.PerfilService.getProfileData();
    this.router.navigate(['/home']);
  }

  displayFn(user: User): string {
    return user && user.name ? `${user.clientNumber} - ${user.name}` : '';
  }

  private loggedUser!: ProfileDataRequest;
  selectedClient(event: any) {
    this.addressSelected = undefined;
    this.clientSelected = event.option.value;
    this.loggedUser = this.PerfilService.getCurrentUserInfo()

    if (this.clientSelected?.clientNumber !== undefined) {
      const subscriptionGetUser = this.SubLoginService.getUser(this.clientSelected?.clientNumber, this.loggedUser.userId).subscribe({
        next: res => {
          this.encrypt.setEncryptedItem(
            StorageKeys.BIO2_CLIENT_ADDRESSES,
            JSON.stringify(res)
          );
          let index = this.users.findIndex(user => user.clientNumber === res.clientNumber);
          Object.assign(this.users[index], res);
        },
        complete: () => {
          subscriptionGetUser.unsubscribe();
        },
        error: error => {
          console.log(error);
          subscriptionGetUser.unsubscribe();
        }
      })
    }
  }

  ngOnDestroy(): void {
    if (this.sugestionsSubs) {
      this.sugestionsSubs.unsubscribe();
    }
  }
}
