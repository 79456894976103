import { ProductShopComponent } from './../product-shop/product-shop.component';
import { CheckoutService } from './../../api/services/checkout.service';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, of } from 'rxjs';
import { CartProductModel } from 'src/app/api/models/checkout';
import { ProfileDataRequest } from 'src/app/api/models/perfil';
import { Bonus, ShopItemModel } from 'src/app/api/models/shop-item.model';
import { CartService } from 'src/app/api/services/cart.service';
import { PerfilService } from 'src/app/api/services/perfil.service';
import { environment } from 'src/environments/environment';
import { SnackBarService } from 'src/app/api/services/snack-bar.service';

interface BonusData {
  referenceBonus: string,
  imageURL: string,
  bonuses: Bonus[]
}
@Component({
  selector: 'app-product-bonus-modal',
  templateUrl: './product-bonus-modal.component.html',
  styleUrls: ['./product-bonus-modal.component.css']
})
export class ProductBonusModalComponent {
  private loggedUser!: ProfileDataRequest;
  private shopCart = new BehaviorSubject<CartProductModel[]>([]);
  public bonusGroups = new Map<string, Bonus[]>();
  bonusData: BonusData[] = []
  constructor(
    public dialogRef: MatDialogRef<ProductBonusModalComponent>,
    private checkoutService: CheckoutService,
    private perfilService: PerfilService,
    private cartService: CartService,
    private snackBarService: SnackBarService,

    @Inject(MAT_DIALOG_DATA) public data: ShopItemModel) {
  }

  ngOnInit(): void {
    this.bonusData = this.getImageProductBonus()
  }

  closeModal() {
    this.dialogRef.close();
  }

  getImageProductBonus() {
    if (this.data.bonus && this.data.bonus.length > 0) {
      this.bonusGroups = new Map<string, Bonus[]>();
      for (const bonus of this.data.bonus) {
        const referenceBonus = bonus.referenceBonus;
        if (!this.bonusGroups.has(referenceBonus)) {
          this.bonusGroups.set(referenceBonus, []);
        }
        const bonusGroup = this.bonusGroups.get(referenceBonus);
        if (bonusGroup) {
          bonusGroup.push(bonus);
        }
      }

      // Return an array of objects containing referenceBonus, image URL, and bonuses
      return Array.from(this.bonusGroups.entries()).map(([referenceBonus, bonuses]) => {
        const imageURL = environment.shop_images + bonuses[0].imageBonus;
        return {
          referenceBonus: referenceBonus,
          imageURL: imageURL,
          bonuses: bonuses
        };
      });
    }
    return [];
  }

  callCrudCart(data: ShopItemModel, bonus: string) {
    this.loggedUser = this.perfilService.getCurrentUserInfo();
    const quantity = parseInt(bonus, 10);
    let shopcard = this.cartService.getShopCart();
    const currentShopCart = shopcard.getValue();
    const updatedCart = [...currentShopCart];
    let existingProduct = updatedCart.find(p => p.productId === data.reference && p.usePoints === false && p.previouslyUsingPoints === false);
    let itemSelected: CartProductModel;
    if (existingProduct) {
      existingProduct.quantity += quantity;
      itemSelected = existingProduct;
    } else {
      itemSelected = {
        userId: this.loggedUser.userId,
        clientId: this.loggedUser.clientId,
        productId: data.reference,
        quantity: quantity,
        previouslyUsingPoints: false,
        usePoints: false,
        points: 0
      };
      updatedCart.push(itemSelected);
    }
    this.shopCart.next(updatedCart);
    this.cartService.updateBackendCart(itemSelected, false).subscribe(opResult => {
      if (opResult) {
        this.cartService.loadCart();
        this.snackBarService.openSnackBar(
          'Produto adicionado ao carrinho!',
          '#0AA174',
          '../../assets/Icons/correct.png'
        );
      }
    });
    this.checkoutService.CrudCartProduct(itemSelected);
  }
}
