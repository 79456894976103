<div class="main-content">
  <app-side-menu></app-side-menu>
  <div class="right-content">
    <app-top-menu></app-top-menu>
    <div *ngIf="isLoading; else contentLoaded" class="loading-animation">
      <app-loading></app-loading>
    </div>
    <ng-template #contentLoaded>
      <div class="main-pub" *ngIf="BCampaignSelected !== undefined" (click)="goToCampaign(BCampaignSelected.id)">
        <img *ngIf="thereIsBCampaign && !isBSlotToShowVideo && !imgExists()" [src]="BCampaignSelected.image"
          alt="Imagem de campanha principal" class="main-pub-img">
        <video *ngIf="thereIsBCampaign && isBSlotToShowVideo && !imgExists()" [src]="BCampaignSelected.image"
          alt="Vídeo de campanha principal" class="main-pub-img" autoplay loop muted></video>
      </div>
      <div class="main-pub"
        *ngIf="BCampaignSelected === undefined && randomVideoSrc !== undefined || imgExists() && this.thereIsBCampaign">
        <div class="video-wrapper">
          <video autoplay muted loop id="banner-video" alt="Vídeo de Banner">
            <source src="{{randomVideoSrc}}" type="video/webm">
          </video>
        </div>
      </div>
      <ng-container *ngIf="emDestaque && emDestaque.length > 0">
        <div class="content-1">
          <div class="d-flex flex-column">
            <span class="span-pub">Produtos em destaque</span>
            <div class="d-flex flex-row align-self-center multiple-cards-prod">
              <app-product-shop *ngFor="let product of emDestaque | slice:0:4" [product]="product"
                (isModalOpen)="isProdModalOpen($event)">
              </app-product-shop>
            </div>
            <div class="d-flex all-products-div">
              <span class="all-products-span" (click)="goTodosProdutos()">Todos os produtos em destaque ></span>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="content d-flex flex-column" style="justify-content: center;">
        <ng-container *ngIf="homeCampList.length <= 0 || !AuthService.hasPermissions(['4']); else campaignsHome">
          <div class="campaign-row last-item welcome w-100">
            <div class="welcome-item">
              <!-- <h3>Bem-vindo(a), {{currentUser.name}}</h3>
              <h1>AO SEU PORTAL VETERENÁRIO</h1> -->
            </div>
          </div>
        </ng-container>
        <ng-template #campaignsHome>
          <span class="span-pub">As nossas campanhas</span>
          <div class="w-100 d-flex" style="justify-content: center !important;">
            <div class="uk-container">
              <div *ngIf="P1CampaignSelected !== undefined" (click)="goToCampaign(P1CampaignSelected.id)"
                class="item-general item1">
                <img *ngIf="!this.isP1SlotToShowVideo && P1CampaignSelected.image" [src]="P1CampaignSelected.image"
                  alt="Imagem publicitária de campanha 1">
                <img *ngIf="!this.isP1SlotToShowVideo && !P1CampaignSelected.image"
                  src="../../../../assets/images/P1CampaignPlaceholder.png" alt="Imagem publicitária de campanha 1">
                <div *ngIf="!!this.isP1SlotToShowVideo" class="video-wrapper">
                  <video autoplay muted loop id="p1-video" id="banner-video" alt="Vídeo publicitário de Campanha 1" style="border-radius: 15px;">
                    <source src="{{P1CampaignSelected.image}}" type="video/webm">
                  </video>
                </div>
              </div>
              <div *ngIf="P2CampaignSelected !== undefined" (click)="goToCampaign(P2CampaignSelected.id)"
                class="item-general item2">
                <img *ngIf="!this.isP2SlotToShowVideo && P2CampaignSelected.image" [src]="P2CampaignSelected.image"
                  alt="Imagem publicitária de campanha 2">
                <img *ngIf="!this.isP2SlotToShowVideo && !P2CampaignSelected.image"
                  src="../../../../assets/images/P3CampaignPlaceholder.png" alt="Imagem publicitária de campanha 2">
                <div *ngIf="!!this.isP2SlotToShowVideo" class="video-wrapper">
                  <video autoplay muted loop id="p2-video" id="banner-video" alt="Vídeo publicitário de campanha 2" style="border-radius: 15px;">
                    <source src="{{P2CampaignSelected.image}}" type="video/webm">
                  </video>
                </div>
              </div>
              <div *ngIf="P3CampaignSelected !== undefined" (click)="goToCampaign(P3CampaignSelected.id)"
                class="item-general item3">
                <img *ngIf="!this.isP3SlotToShowVideo && P3CampaignSelected.image" [src]="P3CampaignSelected.image"
                  alt="Imagem publicitária de campanha 3">
                <img *ngIf="!this.isP3SlotToShowVideo && !P3CampaignSelected.image"
                  src="../../../../assets/images/P3CampaignPlaceholder.png" alt="Imagem publicitária de campanha 3">
                <div *ngIf="!!this.isP3SlotToShowVideo" class="video-wrapper">
                  <video autoplay muted loop id="p3-video" id="banner-video" alt="Vídeo publicitário de campanha 3" style="border-radius: 15px;">
                    <source src="{{P3CampaignSelected.image}}" type="video/webm">
                  </video>
                </div>
              </div>
              <div *ngIf="P4CampaignSelected !== undefined" (click)="goToCampaign(P4CampaignSelected.id)"
                class="item-general item4 last-item">
                <img *ngIf="!this.isP4SlotToShowVideo && P4CampaignSelected.image" [src]="P4CampaignSelected.image"
                  alt="Imagem publicitária de campanha 4">
                <img *ngIf="!this.isP4SlotToShowVideo && !P4CampaignSelected.image"
                  src="../../../../assets/images/P4CampaignPlaceholder.png" alt="Imagem publicitária de campanha 4">
                <div *ngIf="!!this.isP4SlotToShowVideo" class="video-wrapper">
                  <video autoplay muted loop id="p4-video" id="banner-video" alt="Vídeo publicitário de campanha 4" style="border-radius: 15px;">
                    <source src="{{P4CampaignSelected.image}}" type="video/webm">
                  </video>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </ng-template>
    <app-footer></app-footer>
  </div>
  <div class="app-backdrop" [ngClass]="{ 'open': isModalOpen }"></div>
</div>
