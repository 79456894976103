import { NgxMatFileInputComponent } from '@angular-material-components/file-input';
import {
  Component,
  OnInit,
  OnDestroy,
  EventEmitter,
  ViewChild,
  QueryList,
  Input,
  Output,
} from '@angular/core';
import {
  FormGroup,
  NgForm,
  FormBuilder,
  FormArray,
  FormControl,
  Validators,
  Form,
} from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable, Subscription, delay, map, startWith } from 'rxjs';
import { GenericModalComponent } from 'src/app/Custom/generic-modal/generic-modal.component';
import {
  Campaign,
  CampaignGroup,
  CampaignGroupMin,
  CampaignMin,
  ProductMin,
} from 'src/app/api/models/campaigns';
import { TbLaboratorio, TbProduto } from 'src/app/api/models/statistics';
import { BackofficeCampaignService } from 'src/app/api/services/backoffice-campaign.service';
import { CampaignsService } from 'src/app/api/services/campaigns.service';
import { PerfilService } from 'src/app/api/services/perfil.service';
import { SnackBarService } from 'src/app/api/services/snack-bar.service';
import { EditCampaignGroupComponent } from '../../edit-campaign-group/edit-campaign-group.component';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

interface InputField {
  inputType: string;
  formControlName: string;
  formControlNameHeader: string;
  formControlNameGroup: string;
  label: string;
  value?: any;
  styleClass?: string;
}

@Component({
  selector: 'app-create-campaign',
  templateUrl: './create-campaign.component.html',
  styleUrls: ['./create-campaign.component.css'],
})
export class CreateCampaignComponent implements OnInit, OnDestroy {
  @Input() profileRequest: any;
  @Output() selectedLaboratoryChange = new EventEmitter<TbLaboratorio>();

  laboratoryControl = new FormControl<string | TbLaboratorio>('');
  filteredLaboratoryOptions!: Observable<TbLaboratorio[]>;

  selectedlaboratoryOption?: TbLaboratorio;
  constructor(
    private formBuilder: FormBuilder,
    private campaignService: CampaignsService,
    private campaignFormService: BackofficeCampaignService,
    private snackBarService: SnackBarService,
    private router: Router,
    public dialog: MatDialog,
  ) { }

  isLinear = false;
  campaingForm!: FormGroup;
  formGroup!: FormGroup;
  inputList: InputField[] = [];
  productGroup!: FormGroup;
  filteredList: TbProduto[] = [];
  isLoading: boolean = true;
  selectedItem: string = 'nenhum grupo';
  selectedProducts: Map<string, Map<string, ProductMin>> = new Map();
  randomLink!: string;
  imgType = '.png,.jpeg,.jpg,.gif,.webm';
  fileType = '.pdf';
  panelOpenState = false;
  editing = false;
  campaign!: CampaignMin;
  unsubscribe: Subscription = new Subscription();
  groupForm!: FormGroup;
  originalGroupAux!: CampaignGroupMin[];
  selectedItems!: TbProduto[];
  laboratoriesList: TbLaboratorio[] = [];

  ngOnInit(): void {
    this.unsubscribe.add(
      this.campaignFormService.getEditing().subscribe((x) => {
        this.editing = x;
      })
    );

    this.unsubscribe.add(
      this.campaignService.getLabs().subscribe((data) => {
        this.laboratoriesList = data;
      }));

    
    this.selectedProducts.set(this.selectedItem, new Map());
    this.campaingForm = this.formBuilder.group({
      campaignType: new FormControl('', Validators.required),
      campaignName: new FormControl('', Validators.required),
      campaignLink: new FormControl({
        value: this.randomLinkGenerator(),
        disabled: true,
      }),
      startDate: new FormControl('', Validators.required),
      endDate: new FormControl('', Validators.required),
      priceLevel: new FormControl('', Validators.required),
      Header: new FormControl(''),
      ImageCampanha: new FormControl(''),
      filterLaboratory: new FormControl(''),
      imgShop: new FormControl(''),
      bannerHome: new FormControl(''),
      imgBanner: new FormControl(''),
      pdf: new FormControl(''),
    });
    this.formGroup = this.formBuilder.group({
      groupName: new FormControl('', Validators.required),
    });
    this.inputList = [];
    this.productGroup = this.formBuilder.group({});
    this.groupForm = this.campaignFormService.getGroupForm();

    this.isLoading = false;
    this.campaign = this.campaignFormService.getCampaign();

    this.filteredLaboratoryOptions = this.laboratoryControl.valueChanges.pipe(
      startWith(''),
      map((value) => {
        const name = typeof value === 'string' ? value : value?.labName;
        return name ? this.filterLaboratory(name) : this.laboratoriesList.slice();
      })
    );
  }

  displayLaboratory(laboratory: TbLaboratorio): string {
    return laboratory && laboratory.labName ? laboratory.labName : '';
  }

  selectedLaboratory(event: MatAutocompleteSelectedEvent): void {
    this.selectedlaboratoryOption = event.option.value;
    this.selectedLaboratoryChange.emit(this.selectedlaboratoryOption);
  }

  private filterLaboratory(value: string): TbLaboratorio[] {
    const filterValue = value.toLowerCase();
    return this.laboratoriesList.filter((option) =>
      option.labName.toLowerCase().includes(filterValue)
    );
  }

  /*Campanhas*/

  canProcessed(): boolean {
    return this.campaingForm.valid;
  }
  get Header() {
    return this.campaingForm.controls['Header'] as FormControl;
  }
  get ImageCampanha() {
    return this.campaingForm.controls['ImageCampanha'] as FormControl;
  }
  get imgShop() {
    return this.campaingForm.controls['imgShop'] as FormControl;
  }
  get bannerHome() {
    return this.campaingForm.controls['bannerHome'] as FormControl;
  }
  get imgBanner() {
    return this.campaingForm.controls['imgBanner'] as FormControl;
  }
  get pdf() {
    return this.campaingForm.controls['pdf'] as FormControl;
  }

  private randomLinkGenerator(): string {
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const linkLength = 32;

    for (let i = 0; i < linkLength; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }
    this.randomLink = result;
    return result;
  }

  convertDate(dateString: string): string {
    const originalDate: Date = new Date(dateString);
    const formattedDate = new Date(originalDate);
    formattedDate.setHours(formattedDate.getHours() + 1);
    return formattedDate.toISOString();
  }

  createCampaign(): void {
    const sDate = this.convertDate(this.campaingForm.controls['startDate'].value);
    const eDate = this.convertDate(this.campaingForm.controls['endDate'].value);
    this.campaign = {
      name: this.campaingForm.controls['campaignName'].value,
      link: this.campaingForm.controls['campaignLink'].value,
      startingDate: sDate,
      endingDate: eDate,
      type: this.campaingForm.controls['campaignType'].value,
      priceLevel: this.campaingForm.controls['priceLevel'].value,
      header: this.campaingForm.controls['Header'].value,
      image: this.campaingForm.controls['ImageCampanha'].value,
      pdf: this.campaingForm.controls['pdf'].value,
      group: [],
      products: [],
      idlaboratorio: this.selectedlaboratoryOption?.id
    };
    this.campaignFormService.setCampaign(this.campaign);
  }

  createFullCampaign(): void {
    this.isLoading = true;
    this.unsubscribe.add(
      this.campaignService.createNewCampaign(this.campaign)
        .subscribe(
          () => {
            this.router.navigate(['/backoffice/campanhas']);
            this.snackBarService.openSnackBar(
              'Campanha criada com sucesso',
              '#0AA174',
              '../../assets/Icons/correct.png'
            );
          },
          (error) => {
            this.snackBarService.openSnackBar(
              `Erro ao criar campanha`,
              '#F97066',
              '../../../assets/Icons/ErrorIcon.png'
            );
          }
        )
    );
  }

  /*Grupos*/

  handleEditGroup(): void {
    const data = {
      title: '',
      description: '',
      action: 'Guardar',
    };
    const dialogRef = this.dialog.open(GenericModalComponent, {
      data: data,
    });
    this.unsubscribe.add(
      dialogRef.afterClosed().subscribe((result) => {
        if (result == true) {
          this.saveGroupChanges();
        }
      })
    );
  }

  saveGroupChanges() {
    this.campaignFormService.saveEditGroup();
    this.campaignFormService.getEditing().next(false);
  }
  get imgHeaderGroup() {
    return this.campaingForm.controls['imgHeader'] as FormControl;
  }
  get imgGroup() {
    return this.campaingForm.controls['imgHomePage'] as FormControl;
  }

  getFormControl(name: string) {
    return this.formGroup.controls[name] as FormControl;
  }

  getOriginalGroup(event: CampaignGroupMin[]) {
    if (event) {
      this.originalGroupAux = event;
    }
  }

  getSelectedItems(event: TbProduto[]) {
    this.selectedItems = event;
  }

  cancelOperationButton() {
    this.campaignFormService.resetEdit(this.originalGroupAux);
  }

  ngOnDestroy(): void {
    this.unsubscribe.unsubscribe();
  }

  campaignType: { [key: string]: string } = {
    C: '(1200 x 400px)', //Verificar
    E: '(1200 x 400px)', //Verificar
    F: '(1200 x 400px)', //Verificar
    N: '(1200 x 400px)', //Verificar
    P: '(1200 x 400px)', //Verificar
    P1: '(800 x 400px)',
    P2: '(400 x 400px)',
    P3: '(600 x 400px)',
    P4: '(600 x 600px)',
    DH: '(310 x 350px)',
    B: '(1700 x 500px)',
  };

  checkFileSize(
    InputComponent: NgxMatFileInputComponent,
    maxSizeInMB: number
  ): void {
    if (InputComponent) {
      const selectedFile = InputComponent.ngControl.value;
      if (selectedFile) {
        const fileSizeBytes = selectedFile.size;
        const fileSizeMB = fileSizeBytes / (1024 * 1024);
        if (fileSizeMB > 5) {
          this.snackBarService.openSnackBar(
            'Ficheiro demasiado grande (max. 5MB)',
            '#F9841E',
            '../../assets/Icons/Warning-icon.png'
          );
          InputComponent.ngControl.reset();
        }
      }
    }
  }
}
