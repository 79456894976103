<div class="inner-content">
  <div class="inner-div">
    <div class="d-flex flex-row">
      <span class="span-title">Bónus</span>
      <button id='close' (click)="closeModal()">close</button>
    </div>
    <div class="inside-content1">
      <div class="d-flex" *ngFor="let product of bonusData">
        <div *ngFor="let bonus of product.bonuses" class="plus-bonus">
          <span>{{ bonus.bonus }}</span>
          <div class="plus-btn" (click)="callCrudCart(data, bonus.bonus)">+</div>
        </div>
      </div>
    </div>
  </div>
  <div class="inner-div">
    <span class="span-title">Produtos de oferta</span>
    <div class="inside-content2">
      <div *ngFor="let product of bonusData; let i=index">
        <div class="product-bonus">
          <div *ngIf="!product.imageURL.endsWith('product_placeholder.png')">
            <img [src]="product.imageURL" [alt]="product.referenceBonus">
          </div>
          <div *ngIf="product.imageURL.endsWith('product_placeholder.png')" class="placeholderDiv">
            <img [src]="product.imageURL" [alt]="product.referenceBonus">
            <span>Imagem não disponibilizada</span>
          </div>
          <div class="text-center">
            <span>{{ product.bonuses[0].description }}</span>
          </div>
        </div>
      </div>
    </div>
</div>
